<template>
  <div class="user-form-layout withTopBar" >
      <van-nav-bar :title="mode=='add' ? '个人信息填写' : '个人信息编辑'" :left-text="!query.isScan ? '返回' : ''" :left-arrow="!query.isScan" safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
      <!-- <div>center:{{JSON.stringify(center)}}</div> -->
      <div class="loading" v-show="loading">
        <van-loading type="spinner" color="#1989fa">获取位置中</van-loading>
      </div>
      <div class="form-box" v-if="center!='' && !loading">
        <div class="form-item flex_box align_center holder">
          <div class="label wd60">姓名</div>
          <div class="flex_one">
            <van-field 
              class="wb100"
              v-model="user.name"
              placeholder="请输入姓名"
            />
          </div>
        </div>
        <div class="form-item flex_box align_center holder">
          <div class="label wd60">手机</div>
          <div class="flex_one">
            <van-field 
              class="wb100"
              v-model="user.mobile"
              placeholder="请输入手机号"
            />
          </div>
        </div>
        <div class="form-item flex_box align_center holder">
          <div class="label wd60">地区</div>
          <div class="flex_one flex_box align_center space_between" @click="selectPoint">
            <van-field 
              v-if="user.latitude=='' && user.longitude==''"
              class="wb100"
              disabled
              placeholder="请选择所在地区"
            />
            <div class="pdt10 pdb10 pdl15" v-else>{{user.province}}/{{user.city}}/{{user.district}}</div>
            <div class="iconfont icon-weizhi font16 pdl5"></div>
          </div>
        </div>
        <div class="form-item flex_box align_center holder">
          <div class="label wd60">详细地址</div>
          <div class="flex_one">
            <van-field 
              class="wb100"
              v-model="user.address"
              placeholder="街道、楼牌等"
            />
          </div>
        </div>
        <div class="form-item flex_box align_center holder">
          <div class="label wd60">奶箱</div>
          <div class="flex_one pdl10 flex_box align_center pdt10 pdb10">
            <div class="flex_box align_center" @click="user.isbox=0">
              <van-icon name="checked" class="font18 color_red" v-if="user.isbox=='0'" />
              <van-icon name="circle" class="font18 color_gray" v-if="user.isbox=='1'" />
              <div class="pdl5">需要</div>
            </div>
            <div class="flex_box align_center pdl30 " @click="user.isbox=1">
              <van-icon name="checked" class="font18 color_red" v-if="user.isbox=='1'" />
              <van-icon name="circle" class="font18 color_gray" v-if="user.isbox=='0'" />
              <div class="pdl5">不需要</div>
            </div>
          </div>
        </div>
      </div>
      <div class="my_container"></div>
      <div class="address_btn text_center" v-show="!loading" @click="saveAddress">保存</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AMap from 'AMap'
export default {
  name:"user-input",
  components:{},
  data(){
    return{
      loading:false,
      query:{},
      user:{
        name:"",//收货人姓名
        mobile:"",//手机号码
        latitude:"",//纬度
        longitude:"",//经度
        province:"",//省
        city:"",//市
        district:"",//区
        address:"",//详细地址
        isbox:1,//是否需要奶箱
        nx_address:""//奶箱地址
      },
      base:{},
      map:null,
      center:""
    }
  },
  created(){
    this.base = JSON.parse(JSON.stringify(this.user))
    this.query = this.$route.query
    console.log(this.query)
  },
  mounted(){
    let point = this.point
    if(this.isAddress || this.mode == "edit"){
      this.user = JSON.parse(JSON.stringify(this.address))
      console.log(this.user)
      this.$store.commit('setIsAddress', false)
    }
    if(point.longitude){
      this.center = point.longitude+','+point.latitude
      //console.log(this.center)
    }else{
      this.init()
    }
    if(point.latitude && point.longitude){
      this.user.latitude = point.latitude //纬度
      this.user.longitude = point.longitude//经度
      this.user.province = point.province//省
      this.user.city = point.city//
      this.user.district = point.district//
      this.user.address = point.address !='' ? point.address : point.name //详细地址
      this.$store.commit('changePoint', {})
    }
  },
  methods:{
    saveAddress(){
      const _this = this
      if(this.formCheck()){
        if(this.mode =='add'){
          _this.user.sales_id = this.query.sales_id
        }
        _this.$api.common.saveUser(_this.mode,_this.user).then(res=>{
          if(res.code =='0' && res.data){
            console.log(res)
            _this.$toast.success('保存成功')
            if(_this.query.isScan){
              this.user = JSON.parse(JSON.stringify(this.base))
            }else{
              setTimeout(() => {
                _this.$toast.clear()
                _this.$router.push({
                  name: 'user-list',
                  query:_this.query
                })
              }, 500)
            }
          }
        })
      }else{
        this.$toast.fail('正确填写信息');
      }
    },
    formCheck(){//表单校验
      if(this.user.province=='' || this.user.city=='' || this.user.district=='' || this.user.name=='' || this.user.address=='' || this.user.mobile.length != 11 || isNaN(this.user.mobile)){
        return false
      }
      return true
    },
    init() {
      const _this = this
      this.loading = true
      this.map = new AMap.Map('my_container',{
        resizeEnable: true,
        zoom: 18,
      })
      AMap.plugin('AMap.Geolocation',function(){ //异步加载插件
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', function(obj){
          _this.center = obj.position.lng+','+obj.position.lat
          //alert(_this.center)
          _this.loading = false
        });//返回定位信息
      })
    },
    selectPoint(){
      this.$store.commit('setIsAddress', true)
      this.$store.commit('changeAddress', this.user)
      if(this.user.latitude){
        let center = this.user.longitude+','+this.user.latitude
        localStorage.setItem("positionCenter",center)
      }else{
        localStorage.setItem("positionCenter",this.center)
      }
      this.$router.push({
        name: "map-select",
        query:this.query
      })
    },
    onClickLeft(){
      this.$router.push({
        name: "user-list",
        query:this.query
      })
    }
  },
  computed:{
    mode(){
      return this.$route.query.mode ? "add" : 'edit'
    },
    ...mapState(["point","address","isAddress"]),
  }
}
</script>

<style lang="scss">
.my_container{
  display: none;
}
@import "./user-input.scss"
</style>